import { Grid } from '@mui/material';
import { Container } from 'components/layout/Container';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import StoreDescription from 'components/store/StoreDescription';
import styles from './ShopProfile.module.css'


export default function ShopProfile({shopIcon,shop}) {
  return (
    <>
      <div className="desktop">
        <div className={styles.shopIcon}>
          <Container className={styles.main}>
            <img className={styles.icon} src={shopIcon} alt=""/>
            <Grid container spacing={2} className={styles.shopIconGrid}>
              <Grid item xs={12} className={styles.shopName}>{shop?.name}</Grid>
              <Grid item xs={12}>
                <StoreDescription shop={shop}/>
              </Grid>
              <Grid item xs={12}>
                <FollowUsAndMore shop={shop} variant="minimal"/>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <div id="shopScroll2"/>
      <div className="mobile">
        <div className={styles.shopIcon}>
          <img className={styles.icon} src={shopIcon} alt=""/>
          <div>
            <div className={styles.shopName}>{shop?.name}</div>
            <StoreDescription shop={shop}/>
          </div>
        </div>
      </div>
    </>
  )
}